<template>
  <div class="user-manage">
    <b-container>
      <b-card style="margin-bottom: 10px;">
        <b-row>
          <b-col>
            <label for="input-search-userName">UserName:</label>
            <b-input-group size="sm">
              <b-form-input id="input-search-userName" v-model="search.userName" type="search"
                            placeholder="UserName"></b-form-input>
            </b-input-group>
          </b-col>
          <b-col>
          </b-col>
          <b-col>
          </b-col>
          <b-col cols="3" align-self="center" class="text-center">
            <b-button-group>
              <b-button variant="info" @click="handleSearch">Search</b-button>
              <b-button @click="handleReset">Reset</b-button>
            </b-button-group>
          </b-col>
          <b-col cols="1" align-self="center">
            <b-button variant="warning" @click="showUserAddModal">Add</b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-table striped hover :sticky-header="tableHeight+'px'" head-variant="light" table-variant="light"
               :busy.sync="table.isBusy" :fields="table.fields" :items="table.items" show-empty
               :style="{height: tableHeight+'px'}">
        <template #cell(no)="row">
          {{ row.index + 1 }}
        </template>
        <template #cell(createTime)="row">
          {{ dateFormat(row.value) }}
        </template>
        <template #empty="scope">
          <p>{{ scope.emptyText }}</p>
        </template>
      </b-table>
      <b-row>
        <b-col>
          <span style="color: white;">共 <b>{{ table.totalRows }}</b> 条</span>
        </b-col>
        <b-col>
          <b-pagination v-model="table.currentPage" :total-rows="table.totalRows" :per-page="table.perPage"
                        align="right" style="margin-bottom:initial;"></b-pagination>
        </b-col>
      </b-row>
    </b-container>

    <!-- modal:add -->
    <b-modal id="modal-add" title="User Add" ok-only ok-title="确定" @ok.prevent="handleAdd">
      <b-overlay :show="loading" rounded="sm">
        <validation-observer ref="vo-userAdd">
          <b-row align-v="center">
            <b-col cols="3" class="text-right">
              <label for="input-userName" class="label-required">UserName:</label>
            </b-col>
            <b-col cols="8">
              <validation-provider name="userName" rules="required" v-slot="{ errors }">
                <b-form-input id="input-userName" size="sm" v-model="form.user.userName" placeholder="UserName">
                </b-form-input>
                <span class="valid-error">{{ errors[0] }}</span>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row align-v="center" style="margin-top: 10px;">
            <b-col cols="3" class="text-right">
              <label for="input-name" class="label-required">Name:</label>
            </b-col>
            <b-col cols="8">
              <validation-provider name="name" rules="required" v-slot="{ errors }">
                <b-form-input id="input-name" size="sm" v-model="form.user.name" placeholder="Name">
                </b-form-input>
                <span class="valid-error">{{ errors[0] }}</span>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row align-v="center" style="margin-top: 10px;">
            <b-col cols="3" class="text-right">
              <label for="input-remark" class="label-required">Remark:</label>
            </b-col>
            <b-col cols="8">
              <validation-provider name="remark" rules="required" v-slot="{ errors }">
                <b-form-textarea id="input-remark" rows="2" size="sm" v-model="form.user.remark" placeholder="Remark">
                </b-form-textarea>
                <span class="valid-error">{{ errors[0] }}</span>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {dateFormat} from "../assets/js/util";

const formObj = {
  user: {
    userName: '',
    name: '',
    remark: ''
  }
};

export default {
  name: 'userManage',
  data() {
    return {
      search: {
        userName: ''
      },
      table: {
        isBusy: false,
        fields: [
          {key: 'no', label: 'No'},
          {key: 'userName', label: 'UserName', stickyColumn: true, sortable: true, sortDirection: 'desc'},
          {key: 'name', label: 'Name', sortable: true, sortDirection: 'desc'},
          {key: 'roleName', label: 'RoleName', sortable: true, sortDirection: 'desc'},
          {key: 'remark', label: 'Remark', sortable: false},
          {key: 'createTime', label: 'CreateTime', sortable: false}
        ],
        items: [],
        totalRows: 0,
        perPage: 10,
        currentPage: 1
      },
      loading: false,
      form: JSON.parse(JSON.stringify(formObj))
    }
  },
  computed: {
    tableHeight: function () {
      return window.innerHeight - 20 - 18 - 28 - 109 - 16 - 33 - 23;
    }
  },
  methods: {
    loadPage() {
      this.table.items = [];
      this.table.totalRows = 0;

      this.$axios.get('user', Object.assign({}, {
            perPage: this.table.perPage,
            currentPage: this.table.currentPage
          }, this.search)
      )
          .then((res) => {
            if (res.s === 0) {
              let data = res.data;
              this.table.items = data.records;
              this.table.perPage = data.size;
              this.table.currentPage = data.current;
              this.table.totalRows = data.total;
            }
          })
          .catch(err => {
            console.error(err);
          });
    },
    handleSearch() {
      this.loadPage();
    },
    handleReset() {
      this.search.userName = '';
    },
    dateFormat(date) {
      return dateFormat(date, 'yyyy-MM-dd hh:mm:ss');
    },
    showUserAddModal() {
      this.$bvModal.show('modal-add');
    },
    handleAdd() {
      this.$refs['vo-userAdd'].validate()
          .then((valid) => {
            if (valid) {
              this.$bvModal.msgBoxConfirm('Are you sure want to submit?', {
                title: 'Please Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'info',
                okTitle: 'Confirm',
                cancelTitle: 'Cancel'
              })
                  .then(value => {
                    if (value) {
                      this.loading = true;

                      this.$axios.post('user', this.form.user)
                          .then(res => {
                            this.loading = false;

                            this.$nextTick(() => {
                              this.$bvModal.hide('modal-add');
                            });

                            if (res.s === 0) {
                              this.$bvToast.toast('操作成功：' + res.data, {
                                title: '系统提示',
                                variant: 'success',
                                solid: true
                              });

                              this.resetForm();
                            } else {
                              this.$bvToast.toast(res.msg, {
                                title: '系统提示',
                                variant: 'danger',
                                solid: true
                              });
                            }

                            this.loadPage();
                          })
                          .catch(err => {
                            this.loading = false;

                            console.error(err);
                          });
                    }
                  })
                  .catch(err => {
                    console.error(err);
                  })
            }
          })
          .catch(e => {
            console.error(e);
          });
    },
    resetForm() {
      this.form = JSON.parse(JSON.stringify(formObj));
    }
  },
  mounted() {
    this.loadPage();
  }
}
</script>

<style lang="scss">
.user-manage {
  width: 100%;

  .padding-left-right-10 {
    padding: 0 10px;
  }
}
</style>